import { createAction } from '@reduxjs/toolkit';
import {
  ACTIVITY_GET_SUGGESTED_JURISDICTIONS_REQUEST,
  ACTIVITY_GET_SUGGESTED_JURISDICTIONS_REQUEST_ONLINE,
  ACTIVITY_GET_SUGGESTED_JURISDICTIONS_REQUEST_OFFLINE,
  ACTIVITY_GET_SUGGESTED_JURISDICTIONS_SUCCESS,
  ACTIVITY_GET_SUGGESTED_BIOCONTROL_REQUEST_ONLINE,
  ACTIVITY_GET_SUGGESTED_BIOCONTROL_REQUEST_ONLINE_SUCCESS,
  ACTIVITY_GET_SUGGESTED_BIOCONTROL_AGENTS,
  ACTIVITY_GET_SUGGESTED_BIOCONTROL_AGENTS_SUCCESS,
  ACTIVITY_GET_SUGGESTED_PERSONS_REQUEST,
  ACTIVITY_GET_SUGGESTED_PERSONS_REQUEST_ONLINE,
  ACTIVITY_GET_SUGGESTED_PERSONS_REQUEST_OFFLINE,
  ACTIVITY_GET_SUGGESTED_PERSONS_SUCCESS,
  ACTIVITY_GET_SUGGESTED_TREATMENT_IDS_REQUEST,
  ACTIVITY_GET_SUGGESTED_TREATMENT_IDS_REQUEST_ONLINE,
  ACTIVITY_GET_SUGGESTED_TREATMENT_IDS_REQUEST_OFFLINE,
  ACTIVITY_GET_SUGGESTED_TREATMENT_IDS_SUCCESS
} from '../../actions';
import { Geometry } from '@turf/helpers';

class Suggestions {
  static readonly jurisdictions = createAction<Geometry>(ACTIVITY_GET_SUGGESTED_JURISDICTIONS_REQUEST);
  static readonly jurisdictionsOnline = createAction<Geometry>(ACTIVITY_GET_SUGGESTED_JURISDICTIONS_REQUEST_ONLINE);
  static readonly jurisdictionsOffline = createAction(ACTIVITY_GET_SUGGESTED_JURISDICTIONS_REQUEST_OFFLINE);
  static readonly jurisdictionsSuccess = createAction<Geometry[]>(ACTIVITY_GET_SUGGESTED_JURISDICTIONS_SUCCESS);

  static readonly biocontrolAgents = createAction(
    ACTIVITY_GET_SUGGESTED_BIOCONTROL_AGENTS,
    (plantCode: string, agentListTarget: string) => ({
      payload: { plantCode, agentListTarget }
    })
  );
  static readonly biocontrolOnline = createAction(ACTIVITY_GET_SUGGESTED_BIOCONTROL_REQUEST_ONLINE);
  static readonly biocontrolOnlineSuccess = createAction<Record<string, any>[]>(
    ACTIVITY_GET_SUGGESTED_BIOCONTROL_REQUEST_ONLINE_SUCCESS
  );
  static readonly biocontrolAgentsSuccess = createAction(
    ACTIVITY_GET_SUGGESTED_BIOCONTROL_AGENTS_SUCCESS,
    (suggestedBiocontrolTreatments: Record<string, any>[], agentListTarget: string) => ({
      payload: { suggestedBiocontrolTreatments, agentListTarget }
    })
  );

  static readonly persons = createAction(ACTIVITY_GET_SUGGESTED_PERSONS_REQUEST);
  static readonly personsOnline = createAction(ACTIVITY_GET_SUGGESTED_PERSONS_REQUEST_ONLINE);
  static readonly personsOffline = createAction(ACTIVITY_GET_SUGGESTED_PERSONS_REQUEST_OFFLINE);
  static readonly personsSuccess = createAction(ACTIVITY_GET_SUGGESTED_PERSONS_SUCCESS);

  static readonly treatmentIdsRequest = createAction(ACTIVITY_GET_SUGGESTED_TREATMENT_IDS_REQUEST);
  static readonly treatmentIdsRequestOnline = createAction(ACTIVITY_GET_SUGGESTED_TREATMENT_IDS_REQUEST_ONLINE);
  static readonly treatmentIdsRequestOffline = createAction(ACTIVITY_GET_SUGGESTED_TREATMENT_IDS_REQUEST_OFFLINE);
  static readonly treatmentIdsSuccess = createAction(ACTIVITY_GET_SUGGESTED_TREATMENT_IDS_SUCCESS);
}
export default Suggestions;
